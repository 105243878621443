<template>
    <div v-if="currentUser.superAdmin">
        <v-data-table
                :headers="headers"
                :items="socialPosts"
                :options.sync="pagination"
                :server-items-length="totalSocialPosts"
                :loading="loading"
                :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
                class="elevation-1"
        >

          <template v-slot:item.city="{ item }">
            <td class="text-xs-right">
              {{
                item.userProfile != null && item.userProfile.city != null
                    ? item.userProfile.city.name
                    : ""
              }}
            </td>
          </template>

            <template v-slot:item.createDate="{ item }">
                {{ parseDate(item.createDate) }}
            </template>
            <template v-slot:item.userProfileId="{ item }">
                <td class="text-xs-right">
                    {{ item.userProfile != null ? item.userProfile.id : "" }}
                </td>
            </template>
            <template v-slot:item.userName="{ item }">
                <td class="text-xs-right">
                    {{ item.userProfile != null ? item.userProfile.fullName : "" }}
                </td>
            </template>
            <template v-slot:item.email="{ item }">
                <td class="text-xs-right">
                    {{ item.userProfile != null ? item.userProfile.username : "" }}
                </td>
            </template>
            <template v-slot:item.published="{ item }">
                <v-switch
                        v-model="item.published"
                        @click="publishSocialPost(item.id, item.published)"
                ></v-switch>
            </template>
            <template v-slot:item.post="{ item }">
                <v-avatar color="teal" size="26" rounded>
                    <v-icon dark @click="goToPostDialog(item)">mdi-clipboard-text</v-icon>
                </v-avatar>
            </template>
            <template v-slot:item.postPicture="{ item }">
                <td class="text-xs-right">
                    <v-img v-if="item.media!= null && item.media.length > 0"
                           :src="showMainPicture(item.media)"
                           contain
                           max-height="100"
                           max-width="100"
                           @error="
              item.media[0].fullThumbPath1 = 'media/error/imageNotFound.png'
            "
                           @click="goToGallery(item.media)"
                    >
                        <v-avatar
                                v-if="item.media.length > 1"
                                right
                                color="indigo"
                                size="22"
                                rounded
                        >
              <span v-if="item.media.length > 1" class="white--text ">{{
                item.media.length
              }}</span>
                        </v-avatar>
                    </v-img>
                </td>
            </template>
            <template v-slot:body.prepend>
                <tr>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                  <td>
                    <v-text-field v-model="userName" type="text"
                                  @keyup.enter="page = 1;retrieveSocialPosts();"></v-text-field>
                  </td>
                  <td>
                    <v-autocomplete
                        v-model="userCity"
                        :items="userCities"
                        :loading="citiesIsLoading"
                        :search-input.sync="search"
                        clearable
                        item-text="name"
                        item-value="id"
                        label="City"
                        @keyup.enter="page = 1;retrieveSocialPosts();"

                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Search for
                            <strong>cities</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title
                                v-html="data.item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </td>
                    <td>
                        <v-text-field v-model="email" type="text"
                                      @keyup.enter="page = 1;retrieveSocialPosts();"></v-text-field>
                    </td>

                    <td>
                        <v-menu
                                ref="dateRangeMenu"
                                v-model="dateRangeMenu"
                                :close-on-content-click="false"
                                :return-value.sync="dates"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="dates"
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateRangeMenu = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="
                    $refs.dateRangeMenu.save(dates);
                    retrieveSocialPosts();
                  "
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>

                    <td>
                        <v-text-field v-model="postDescription" type="text"
                                      @keyup.enter="page = 1;retrieveSocialPosts();"></v-text-field>
                    </td>
                    <td>
                        <v-select
                                :items="postPublishedList"
                                v-model="postPublished"
                                dense
                                hide-details
                                @keyup.enter="page = 1;retrieveSocialPosts();"
                        ></v-select>
                    </td>
                    <td>
                        <v-row>
                            <v-col>
                                <v-avatar color="indigo" size="30">
                                    <v-icon
                                            dark
                                            @click="
                      page = 1;
                      retrieveSocialPosts();
                    "
                                    >mdi-magnify
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <v-avatar color="grey" size="30">
                                    <v-icon
                                            dark
                                            @click="
                      page = 1;
                      clearSearch();
                    "
                                    >mdi-close
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <SocialPostDialog
                v-model="showPostDialog"
                @close="showPostDialog = false"
                :socialPost="socialPost"
        />
        <GalleryDialog
                v-model="showGalleryDialog"
                @close="showGalleryDialog = false"
                :mediaList="mediaList"
        />
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import SocialPostDialog from "@/view/pages/SocialPostDialog";
    import GalleryDialog from "@/view/pages/GalleryDialog";
    import moment from "moment";
    import {mapGetters} from "vuex";

    export default {
        name : "social-posts" ,
        computed : {
            ...mapGetters(["currentUser"]),
            name(){return this.$i18n.t('menu.socialPosts')},
            headers() {
                return [
                    {
                        text : this.$i18n.t ( 'pages.socialPosts.userProfileId' ) ,
                        align : "start" ,
                        sortable : false ,
                        value : "userProfileId"
                    } ,
                    { text : this.$i18n.t ( 'commonTable.userName' ) , value : "userName" , sortable : false } ,
                    { text : this.$i18n.t ( 'commonTable.city' ) , value : "city" , sortable : false } ,
                    { text : this.$i18n.t ( 'commonTable.email' ) , value : "email" , sortable : false } ,
                    { text : this.$i18n.t ( 'commonTable.date' ) , value : "createDate" } ,
                    {
                        text : this.$i18n.t ( 'pages.socialPosts.postPicture' ) ,
                        value : "postPicture" ,
                        sortable : false
                    } ,
                    {
                        text : this.$i18n.t ( 'pages.socialPosts.postDescription' ) ,
                        value : "description" ,
                        sortable : false
                    } ,
                    {
                        text : this.$i18n.t ( 'pages.socialPosts.published' ) ,
                        value : "published" ,
                        width : "10%" ,
                        sortable : false
                    } ,
                    {
                        text : this.$i18n.t ( 'pages.socialPosts.post' ) ,
                        value : "post" ,
                        align : "center" ,
                        sortable : false
                    }
                ]
            }
        } ,
        data() {
            return {
                dates : [] ,
                dateRangeMenu : false ,
                userName : "" ,
                email : "" ,
                postPublished : "" ,
                totalSocialPosts : 0 ,
                postDescription : "" ,
                socialPosts : [] ,
                loading : true ,
                showPostDialog : false ,
                showGalleryDialog : false ,
                socialPost : null ,
                mediaList : [] ,
                pagination : {} ,
                newSearch : false,
                userCity: "",
                userCities: [],
                citiesIsLoading: false,
                search: "",
                postPublishedList : ["All" , "No" , "Yes"] ,
                errors : []
            };
        } ,
        components : {
            SocialPostDialog ,
            GalleryDialog
        } ,
        watch : {
            pagination : {
                handler() {
                    this.retrieveSocialPosts ();
                } ,
                deep : true
            }
        } ,
      created() {
        this.searchCities();
      },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{ title : this.name }]);
            } ,
        methods : {
          searchCities() {
            // Items have already been loaded
            if (this.userCities.length > 0) return;

            // Items have already been requested
            if (this.citiesIsLoading) return;

            this.citiesIsLoading = true;
            ApiService.query("api/cities")
                .then(res => {
                  this.userCities = res.data;
                })
                .catch(error => {
                  this.$log.error("Error: ", error);
                  this.errored = true;
                })
                .finally(() => (this.citiesIsLoading = false));
          },
            parseDate(date) {
                if (date == null) return "";
                return moment
                    .utc ( date )
                    .local ()
                    .format ( "yyyy-MM-DD HH:mm:ss" );
            } ,
            showMainPicture : function showMainPicture(mediaSet) {
                return mediaSet.filter ( row => row.order === 1 )[0].fullThumbPath1;
            } ,
            goToGallery(mediaList) {
                this.showGalleryDialog = true;
                this.mediaList = mediaList;
            } ,
            goToPostDialog(socialPost) {
                this.showPostDialog = true;
                this.socialPost = socialPost;
            } ,
            publishSocialPost(id , publish) {
                this.loading = true;
                ApiService.patch ( `api/social-posts/${id}` , {
                    published : publish
                } )
                    .then ( response => {
                        this.$log.debug ( "Published: " , response.data.published );
                        this.retrieveSocialPosts ();
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.loading = false) );
            } ,
            getRequestParams(userName , email , dates , postDescription , postPublished) {
                let params = {};

                if (userName) {
                    params["userName"] = userName;
                }

                if (email) {
                    params["email"] = email;
                }

                if (dates != null && dates[0] != null) {
                    params["dateRange"] = dates.join ( "," );
                }

                if (postDescription) {
                    params["postDescription"] = postDescription;
                }

                if (postPublished) {
                    params["postPublished"] = postPublished;
                }

                let sort;
                let sortBy = this.pagination.sortBy;
                let sortDesc = this.pagination.sortDesc;
                if (sortBy.length === 1 && sortDesc.length === 1) {
                    sort = sortBy[0];
                    if (sortDesc[0] === true) sort += ",desc";
                }
                params["sort"] = sort;
                params["page"] = this.pagination.page;
                params["size"] = this.pagination.itemsPerPage;

                return params;
            } ,
            clearSearch() {
                (this.userName = ""),
                    (this.email = ""),
                    (this.postDescription = ""),
                    (this.postPublished = ""),
                    (this.dates = []);
            } ,

            retrieveSocialPosts() {
                const params = this.getRequestParams (
                    this.userName ,
                    this.email ,
                    this.dates ,
                    this.postDescription ,
                    this.postPublished
                );

                return new Promise ( resolve => {
                    this.loading = true;
                    ApiService.query ( "api/social-posts" , {
                        params
                    } ).then ( response => {
                        // JSON responses are automatically parsed.
                        this.$log.debug ( "Social posts: " , response.data.content );
                        this.socialPosts = response.data.content;
                        this.totalSocialPosts = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.loading = false;
                        let title = this.totalSocialPosts;
                        this.$store.dispatch ( SET_BREADCRUMB , [{ title : `${this.name}: ` + title }] );
                        resolve ();
                    } );
                } );
            }
        }
    };
</script>

<style>
    .v-data-table.v-data-table.v-data-table >>> td {
        font-size: 16px;
    }

    .v-icon.v-icon {
        font-size: 24px !important;
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba(236, 237, 237);
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgb(250, 250, 250);
    }
</style>
