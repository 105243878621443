<template>
  <v-dialog v-model="show" max-width="350px">
    <div class="d-flex flex-row">
      <div
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
        id="kt_profile_aside"
      >
        <div class="card card-custom card-stretch">
          <div class="card-body pt-4">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
              >
                <div
                  v-if="socialPost != null"
                  class="symbol-label"
                  :style="{
                    backgroundImage: `url(${socialPost.media[0].fullThumbPath1})`
                  }"
                ></div>
                <i class="symbol-badge bg-success"></i>
              </div>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="py-9">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">{{$t ( 'pages.socialPosts.postCreator' )}}:</span>
                <a class="text-muted text-hover-primary">{{
                  socialPost != null && socialPost.userProfile != null
                    ? socialPost.userProfile.fullName
                    : ""
                }}</a>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">{{$t ( 'common.createdOn' )}}</span>
                <a class="text-muted text-hover-primary">{{
                  socialPost != null ? socialPost.createDate : ""
                }}</a>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">{{$t ( 'pages.socialPosts.content' )}}:</span>
                <a class="text-muted text-hover-primary">{{
                  socialPost != null ? socialPost.description : ""
                }}</a>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">{{$t ( 'pages.socialPosts.likeCount' )}}:</span>
                <span class="text-muted">{{
                  socialPost != null ? socialPost.likeCount : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">{{$t ( 'pages.socialPosts.commentCount' )}}:</span>
                <span class="text-muted">{{
                  socialPost != null ? socialPost.commentCount : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">{{$t ( 'pages.socialPosts.sharedPostType' )}}:</span>
                <span class="text-muted">{{
                  socialPost != null ? socialPost.sharedPostType : ""
                }}</span>
              </div>
            </div>
            <!--end::Contact-->
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  props: {
    modal: {
      default: false
    },
    socialPost: {
      type: Object
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Social Post" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
</script>
